import React from "react"
import { AppProvider, Page, LegacyCard } from "@shopify/polaris";

import {
    refreshAuth
} from "../helpers/auth"

class Reauth extends React.Component {
    componentDidMount = async () => {
        if (typeof window !== 'undefined') {
            await refreshAuth()
        }
    }

    render() {
        return (
            <AppProvider
                apiKey={process.env.GATSBY_SHOPIFY_APP_API_KEY}
                forceRedirect={(process.env.NODE_ENV === 'development') ? false : true}
            >
                <Page title="Authenticating...">
                    <LegacyCard>
                        <LegacyCard.Section>
                            <p>Please wait while we authenticate with Shopify...</p>
                        </LegacyCard.Section>
                    </LegacyCard>
                </Page>
            </AppProvider>
        )
    }
}

export default Reauth